<template>
  <div>
    <b-form-group>
      <h5>Pilot License Information</h5>
      <div class="form-group row">
        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.license_no.$error }"
          >
            <label class="col-form-label" for="pilotLicense"
              >License Number</label
            >
            <input
              id="pilotLicense"
              type="text"
              class="form-control"
              placeholder="Enter license number"
              :class="{ 'is-invalid': $v.license_no.$error }"
              v-model="license_no"
            />
            <span
              v-if="$v.license_no.$error && !$v.license_no.required"
              class="help-block invalid-feedback"
              >License Number is required</span
            >
          </div>
        </div>
        <!-- License Number -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="licenseAuthority"
              >License Authority</label
            >
            <b-form-select
              id="licenseAuthority"
              v-model="licenseAuthority"
              :class="{ 'is-invalid': $v.licenseAuthority.$error }"
              :options="listLicenseAuthorities"
            ></b-form-select>
            <span
              v-if="$v.licenseAuthority.$error && !$v.licenseAuthority.required"
              class="help-block invalid-feedback"
              >licenseAuthority is required</span
            >
          </div>
        </div>
        <!-- License Issuing Authority -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px">
            <label class="col-form-label" for="licenseIssuedOn"
              >License Issue Date</label
            >
            <b-form-datepicker
              id="licenseIssuedOn"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              placeholder="Choose a date"
              local="en"
              v-model="license_issued_date"
              :class="{ 'is-invalid': $v.license_issued_date.$error }"
            ></b-form-datepicker>
            <span
              v-if="
                $v.license_issued_date.$error &&
                !$v.license_issued_date.required
              "
              class="help-block invalid-feedback"
              >License Issue Date is required</span
            >
          </div>
        </div>
        <!-- License Issue Date -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="licensExpiry"
              >License Expiry Date</label
            >
            <b-form-datepicker
              id="licensExpiry"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              placeholder="Choose a date"
              local="en"
              v-model="$v.license_expiry_date.$model"
              :class="{ 'is-invalid': $v.license_expiry_date.$error }"
            ></b-form-datepicker>
            <span
              v-if="
                $v.license_expiry_date.$error &&
                !$v.license_expiry_date.required
              "
              class="help-block invalid-feedback"
              >License Expiry Date is required</span
            >
            <span
              v-else-if="
                $v.license_expiry_date.$error &&
                !$v.license_expiry_date.minValue
              "
              class="help-block invalid-feedback"
              >License Expiry Date should be greater than Issued Date</span
            >
          </div>
        </div>
        <!-- License Expiry Date -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="licenseFirstIssuedOn"
              >First License Issued On</label
            >
            <b-form-datepicker
              id="licenseFirstIssuedOn"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              placeholder="Choose a date"
              local="en"
              v-model="$v.license_first_issued_date.$model"
              :class="{ 'is-invalid': $v.license_first_issued_date.$error }"
            ></b-form-datepicker>
            <span
              v-if="
                $v.license_first_issued_date.$error &&
                !$v.license_first_issued_date.required
              "
              class="help-block invalid-feedback"
              >License First Issued Date is required</span
            >
            <span
              v-else-if="
                $v.license_first_issued_date.$error &&
                !$v.license_first_issued_date.minValue
              "
              class="help-block invalid-feedback"
              >License First Date should be less than Issued Date</span
            >
          </div>
        </div>
        <!-- First License Issued on -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="tandemHours"
              >Tandem Flying Hours</label
            >
            <input
              id="tandemHours"
              type="number"
              class="form-control"
              placeholder="Hours clocked on tandem"
              v-model.number="tandemHours"
              :class="{ 'is-invalid': $v.tandemHours.$error }"
            />
            <span
              v-if="$v.tandemHours.$error && !$v.tandemHours.required"
              class="help-block invalid-feedback"
              >Tandem Flying Hours is required
            </span>
            <span
              v-else-if="!$v.tandemHours.numeric"
              class="help-block invalid-feedback"
              >Tandem Flying Hours must be positive</span
            >
          </div>
        </div>
        <!-- Tandem Flying Hours -->
      </div>
    </b-form-group>
    <!-- end row -->

    <b-form-group>
      <h5>Insurance</h5>
      <div class="form-group row">
        <div class="col-12">
          <div class="form-group row mb-3">
            <b-form-group
              label="Does the pilot has insurance?"
              class="insurance-radio"
            >
              <b-form-radio
                v-model="insuranceAvailable"
                name="pilot-insurance"
                :value="true"
                >Yes</b-form-radio
              >
              <b-form-radio
                v-model="insuranceAvailable"
                name="pilot-insurance"
                :value="false"
                >No</b-form-radio
              >
            </b-form-group>
          </div>
        </div>
        <!-- Pilot has Insurance? -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="insuranceProvider"
              >Insurance Provider</label
            >
            <input
              id="insuranceProvider"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.insurance_provider.$error }"
              placeholder="Enter insurance provider"
              v-model="$v.insurance_provider.$model"
              :disabled="!insuranceAvailable"
            />
            <span
              v-if="
                $v.insurance_provider.$error && !$v.insurance_provider.required
              "
              class="help-block invalid-feedback"
              >Insurance provider is required</span
            >
          </div>
        </div>
        <!-- Insurance Provider -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="insurancePolicyNumber"
              >Policy Number</label
            >
            <input
              id="insurancePolicyNumber"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.insurance_policy_no.$error }"
              placeholder="Enter policy number"
              v-model="insurance_policy_no"
              :disabled="!insuranceAvailable"
            />
            <span
              v-if="
                $v.insurance_policy_no.$error &&
                !$v.insurance_policy_no.required
              "
              class="help-block invalid-feedback"
              >Insurance policy no is required</span
            >
          </div>
        </div>
        <!-- Policy Number -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="policyValidFrom"
              >Policy Valid From</label
            >
            <b-form-datepicker
              id="policyValidFrom"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              placeholder="Choose a date"
              local="en"
              v-model="policy_start_date"
              :class="{ 'is-invalid': $v.policy_start_date.$error }"
              :disabled="!insuranceAvailable"
            ></b-form-datepicker>
            <span
              v-if="
                $v.policy_start_date.$error && !$v.policy_start_date.required
              "
              class="help-block invalid-feedback"
              >Policy Valid from date is required</span
            >
          </div>
        </div>
        <!-- Policy valid from -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="policyValidTill"
              >Policy Valid Till</label
            >
            <b-form-datepicker
              id="policyValidTill"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              placeholder="Choose a date"
              local="en"
              v-model="$v.policy_end_date.$model"
              :class="{ 'is-invalid': $v.policy_end_date.$error }"
              :disabled="!insuranceAvailable"
            ></b-form-datepicker>
            <span
              v-if="$v.policy_end_date.$error && !$v.policy_end_date.required"
              class="help-block invalid-feedback"
              >Policy Valid till date is required</span
            >
            <span
              v-if="$v.policy_end_date.$error && !$v.policy_end_date.minValue"
              class="help-block invalid-feedback"
              >Policy Valid till date cannot be greater than from date</span
            >
          </div>
        </div>
        <!-- Policy valid till -->
      </div>
    </b-form-group>
    <!-- end row -->
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";

export default {
  name: "licenseInformation",
  props: {
    pilotData: {
      type: Object,
    },
    clearFormData: {
      type: Boolean,
    },
  },
  data() {
    return {
      license_no: "",
      listLicenseAuthorities: [
        { value: null, text: "Licensing Authority" },
        {
          value: "Dy. Director, Tourism & Civil Aviation, Kangra",
          text: "Dy. Director, Tourism & Civil Aviation, Kangra",
        },
        {
          value: "Dy. Director, Tourism & Civil Aviation, Kullu",
          text: "Dy. Director, Tourism & Civil Aviation, Kullu",
        },
        {
          value: "Paragliding Association of India",
          text: "Paragliding Association of India",
        },
        {
          value: "British Hang Gliding and Paragliding Association",
          text: "British Hang Gliding and Paragliding Association",
        },
        {
          value: "Association of Paragliding Pilots and Instructors",
          text: "Association of Paragliding Pilots and Instructors",
        },
        {
          value: "Swiss Hang Gliding Association",
          text: "Swiss Hang Gliding Association",
        },
      ],
      license_issued_date: null,
      license_expiry_date: null,
      license_first_issued_date: null,
      licenseAuthority: null,
      tandemHours: 0,
      insuranceAvailable: false,
      insurance_provider: "",
      insurance_policy_no: "",
      policy_start_date: null,
      policy_end_date: null,
    };
  },
  validations: {
    license_no: {
      required,
    },
    license_issued_date: {
      required,
    },
    license_expiry_date: {
      required,
      minValue: (value, vm) => value > vm.license_issued_date,
    },
    license_first_issued_date: {
      required,
      maxValue: (value, vm) => value < vm.license_issued_date,
    },
    licenseAuthority: {
      required,
    },
    tandemHours: {
      required,
      numeric,
    },
    insurance_provider: {
      required: function () {
        return this.insuranceAvailable ? !!this.insurance_provider : true;
      },
    },
    insurance_policy_no: {
      required: function () {
        return this.insuranceAvailable ? !!this.insurance_policy_no : true;
      },
    },
    policy_start_date: {
      required: function () {
        return this.insuranceAvailable ? !!this.policy_start_date : true;
      },
    },
    policy_end_date: {
      required: function () {
        return this.insuranceAvailable ? !!this.policy_end_date : true;
      },
      minValue: function () {
        return this.insuranceAvailable
          ? !!(this.policy_start_date < this.policy_end_date)
          : true;
      },
    },
    form: [
      "license_no",
      "license_issued_date",
      "license_expiry_date",
      "license_first_issued_date",
      "licenseAuthority",
      "tandemHours",
      "insurance_provider",
      "insurance_policy_no",
      "policy_start_date",
      "policy_end_date",
    ],
  },
  watch: {
    pilotData() {
      this.populateForm();
    },
    clearFormData() {
      this.license_no = "";
      this.licenseAuthority = null;
      this.license_issued_date = null;
      this.license_expiry_date = null;
      this.license_first_issued_date = null;
      this.tandemHours = null;

      if (this.pilotData.pilot_insurance) {
        this.insuranceAvailable = true;
        this.insurance_provider = "";
        this.insurance_policy_no = "";
        this.policy_start_date = null;
        this.policy_end_date = null;
      }
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    populateForm() {
      if (Object.keys(this.pilotData).length) {
        this.license_no = this.pilotData.pilot_license.license_no;
        this.licenseAuthority = this.pilotData.pilot_license.issuing_authority;
        this.license_issued_date = this.pilotData.pilot_license.issue_date;
        this.license_expiry_date = this.pilotData.pilot_license.expiry_date;
        this.license_first_issued_date = this.pilotData.pilot_license.first_issue_date;
        this.tandemHours = this.pilotData.tandem_flying_hours;

        if (this.pilotData.pilot_insurance) {
          this.insuranceAvailable = true;
          this.insurance_provider = this.pilotData.pilot_insurance.insurance_provider;
          this.insurance_policy_no = this.pilotData.pilot_insurance.policy_no;
          this.policy_start_date = this.pilotData.pilot_insurance.issue_date;
          this.policy_end_date = this.pilotData.pilot_insurance.expiry_date;
        }
      }
    },
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
  },
};
</script>
