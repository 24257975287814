<template>
  <div>
    <b-form-group>
      <h5>Pilot Information</h5>
      <div class="form-group row">
        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.first_name.$error }"
          >
            <label class="col-form-label" for="firstName">First Name</label>
            <input
              id="firstName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.first_name.$error }"
              placeholder="Enter first name"
              v-model.trim="first_name"
            />
            <span
              v-if="$v.first_name.$error && !$v.first_name.required"
              class="help-block invalid-feedback"
              >First name is required</span
            >
            <span
              v-else-if="$v.first_name.$error && !$v.first_name.minLength"
              class="help-block invalid-feedback"
              >Enter at least 3 characters</span
            >
            <span
              v-else-if="$v.first_name.$error && !$v.first_name.alpha"
              class="help-block invalid-feedback"
              >Name must be only alpha characters</span
            >
          </div>
        </div>
        <!-- First Name -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.last_name.$error }"
          >
            <label class="col-form-label" for="lastName">Last Name</label>
            <input
              id="lastName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.last_name.$error }"
              placeholder="Enter last name"
              v-model="last_name"
            />
            <span
              v-if="$v.last_name.$error && !$v.last_name.required"
              class="help-block invalid-feedback"
              >Last name is required</span
            >
            <span
              v-else-if="$v.last_name.$error && !$v.last_name.minLength"
              class="help-block invalid-feedback"
              >Enter at least 3 characters</span
            >
            <span
              v-else-if="$v.last_name.$error && !$v.last_name.alpha"
              class="help-block invalid-feedback"
              >Name must be only alpha characters</span
            >
          </div>
        </div>
        <!-- Last Name -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.email.$error }"
          >
            <label class="col-form-label" for="email">Email</label>
            <input
              id="email"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': $v.email.$error }"
              placeholder="Enter email address"
              v-model.trim="email"
            />
            <span
              v-if="$v.email.$error && !$v.email.required"
              class="help-block invalid-feedback"
              >Email is required</span
            >
            <span
              v-if="$v.email.$error && !$v.email.email"
              class="help-block invalid-feedback"
              >This is not a valid email</span
            >
          </div>
        </div>
        <!-- Email address -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.confirmEmail.$error }"
          >
            <label class="col-form-label" for="emailRepeat">Repeat Email</label>
            <input
              id="emailRepeat"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': $v.confirmEmail.$error }"
              placeholder="Re-enter email address"
              v-model.trim="confirmEmail"
            />
            <span
              v-if="$v.confirmEmail.$error && !$v.confirmEmail.required"
              class="help-block invalid-feedback"
              >Email is required</span
            >
            <span
              v-if="$v.confirmEmail.$error && !$v.confirmEmail.sameAsEmail"
              class="help-block invalid-feedback"
              >Email does not match
            </span>
          </div>
        </div>
        <!-- Confirm Email address -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.mobile_no.$error }"
          >
            <label class="col-form-label" for="contactMobile"
              >Mobile Number</label
            >
            <input
              id="contactMobile"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': $v.mobile_no.$error }"
              placeholder="Enter mobile number"
              v-model="mobile_no"
            />
            <span
              v-if="$v.mobile_no.$error && !$v.mobile_no.required"
              class="help-block invalid-feedback"
              >Mobile number is required</span
            >
            <span
              v-else-if="!$v.mobile_no.numeric"
              class="help-block invalid-feedback"
              >Mobile number must be postive number</span
            >
            <span
              v-else-if="!$v.mobile_no.minLength"
              class="help-block invalid-feedback"
            >
              Mobile number should be minimum 10
            </span>
            <span
              v-else-if="!$v.mobile_no.maxLength"
              class="help-block invalid-feedback"
            >
              Mobile number should not be more than 10
            </span>
          </div>
        </div>
        <!-- Mobile Number -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.alternate_contact_no.$error }"
          >
            <label class="col-form-label" for="contactAlternate"
              >Alternate Contact Number</label
            >
            <input
              id="contactAlternate"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': $v.alternate_contact_no.$error }"
              placeholder="Enter alternate contact number"
              v-model="alternate_contact_no"
            />
            <span
              v-if="$v.alternate_contact_no.$error"
              class="help-block invalid-feedback"
              >alternate contact no is not valid</span
            >
            <span
              v-else-if="!$v.alternate_contact_no.numeric"
              class="help-block invalid-feedback"
            >
              Alternate number must be postive number</span
            >
            <span
              v-else-if="!$v.alternate_contact_no.minLength"
              class="help-block invalid-feedback"
            >
              Alternate number should be minimum 10
            </span>
            <span
              v-else-if="!$v.alternate_contact_no.maxLength"
              class="help-block invalid-feedback"
            >
              Alternate number should not be more than 10
            </span>
          </div>
        </div>
        <!-- Alternate Contact -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.mobile_no.$error }"
          >
            <label class="col-form-label" for="address">Address</label>
            <input
              id="address"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.mobile_no.$error }"
              placeholder="Enter address"
              v-model="address"
            />
            <span
              v-if="$v.address.$error && !$v.address.required"
              class="help-block invalid-feedback"
              >Address is required</span
            >
          </div>
        </div>
        <!-- Address -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.weight.$error }"
          >
            <label class="col-form-label" for="weight">Weight</label>
            <input
              id="weight"
              type="number"
              class="form-control"
              :class="{ 'is-invalid': $v.weight.$error }"
              placeholder="Enter weight"
              v-model.number="weight"
            />
            <span
              v-if="$v.weight.$error && !$v.weight.required"
              class="help-block invalid-feedback"
              >Weight is required</span
            >
            <span v-if="!$v.weight.numeric" class="help-block invalid-feedback">
              Weight must be postive number</span
            >
          </div>
        </div>
        <!-- Alternate Contact -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="idProofType">ID Proof</label>
            <b-form-select
              id="idProofType"
              v-model="idProofType"
              :class="{ 'is-invalid': $v.idProofType.$error }"
            >
              <b-form-select-option value="null"
                >Select ID Proof Type</b-form-select-option
              >
              <b-form-select-option value="Aadhaar"
                >Aadhaar</b-form-select-option
              >
              <b-form-select-option value="Passport"
                >Passport</b-form-select-option
              >
              <b-form-select-option value="DrivingLicense"
                >Driving License</b-form-select-option
              >
              <b-form-select-option value="VoterID"
                >Voter ID</b-form-select-option
              >
            </b-form-select>
            <span
              v-if="$v.idProofType.$error && !$v.idProofType.required"
              class="help-block invalid-feedback"
              >ID Proof Type is required</span
            >
            <!-- Add Validation -->
          </div>
        </div>
        <!-- ID Proof Type -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="idProofNumber"
              >ID proof number</label
            >
            <input
              id="idProofNumber"
              type="text"
              class="form-control"
              placeholder="Enter ID document number"
              v-model="idProofNumber"
              :class="{ 'is-invalid': $v.idProofNumber.$error }"
            />
            <span
              v-if="$v.idProofNumber.$error && !$v.idProofNumber.required"
              class="help-block invalid-feedback"
              >ID Proof Number is required</span
            >
          </div>
        </div>
        <!-- ID Proof Number -->
      </div>
    </b-form-group>
    <!-- end row -->

    <b-form-group>
      <h5>Operator Linking</h5>
      <div class="form-group row">
        <div class="col-sm-4">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.selectedZone.$error }"
          >
            <label class="col-form-label" for="zone">Zone</label>
            <b-form-select
              id="zone"
              class="form-control"
              :class="{ 'is-invalid': $v.selectedZone.$error }"
              v-model="selectedZone"
            >
              <b-form-select-option :value="null">
                Select a Zone
              </b-form-select-option>
              <template v-for="item in zoneTable">
                <b-form-select-option :key="item.id" :value="item.id">
                  {{ item.name }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <span
              v-if="$v.selectedZone.$error && !$v.selectedZone.required"
              class="help-block invalid-feedback"
              >Select a Zone
            </span>
          </div>
        </div>
        <!-- Zone -->

        <div class="col-sm-8">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.selectedOperator.$error }"
          >
            <label class="col-form-label" for="operator">Operator</label>
            <b-form-select
              id="operator"
              class="form-control"
              :class="{ 'is-invalid': $v.selectedOperator.$error }"
              v-model="selectedOperator"
            >
              <b-form-select-option :value="null">
                Select an Operator
              </b-form-select-option>
              <template v-for="item in newFilteredOperatorList">
                <b-form-select-option
                  :key="item.operator_id"
                  :value="item.operator_id"
                >
                  {{ item.company_name }}
                </b-form-select-option>
              </template>
            </b-form-select>
            <span
              v-if="$v.selectedOperator.$error && !$v.selectedOperator.required"
              class="help-block invalid-feedback"
              >Select an Operator</span
            >
          </div>
        </div>
        <!-- Operator -->

        <div class="col-12">
          <div class="form-group row mb-1">
            <label class="col-form-label" for="operatorContact"
              >Operator Contact</label
            >
            <input
              id="operatorContact"
              type="tel"
              class="form-control"
              placeholder="Display operator phone number"
              v-model="operator_contact_no"
              disabled="true"
            />
          </div>
        </div>
        <!-- Operator Contact -->
      </div>
    </b-form-group>
    <!-- end row -->
  </div>
</template>

<script>
import { GET_ZONES, GET_OPERATORS_ADD_PILOT } from "@/graphql/queries/queries";
import {
  required,
  email,
  sameAs,
  minLength,
  numeric,
  alpha,
} from "vuelidate/lib/validators";
import maxLength from "vuelidate/lib/validators/maxLength";

const ZONES_ATTRIBUTE = "zone";
const OPERATORS_ATTRIBUTE = "operators";

export default {
  name: "personalInformation",
  props: {
    updateMode: {
      type: String,
      default: null,
    },
    pilotData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      confirmEmail: "",
      mobile_no: null,
      alternate_contact_no: null,
      address: "",
      weight: 0,
      idProofType: null,
      idProofNumber: null,
      zoneTable: [],
      operatorsTable: [],
      newFilteredOperatorList: [],
      selectedZone: null,
      selectedOperator: null,
      operator_contact_no: null,
    };
  },
  validations: {
    first_name: {
      required,
      alpha,
      minLength: minLength(3),
    },
    last_name: {
      required,
      alpha,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
    confirmEmail: {
      required,
      sameAsEmail: sameAs("email"),
    },
    mobile_no: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(11),
    },
    alternate_contact_no: {
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(12),
    },
    address: {
      required,
    },
    weight: {
      required,
      numeric,
    },
    idProofType: {
      required,
    },
    idProofNumber: {
      required,
    },
    selectedZone: {
      required,
    },
    selectedOperator: {
      required,
    },
    // operator_contact_no: {
    //   required
    // },
    form: [
      "first_name",
      "last_name",
      "email",
      "confirmEmail",
      "mobile_no",
      "alternate_contact_no",
      "idProofType",
      "idProofNumber",
      "selectedZone",
      "selectedOperator",
      // "operator_contact_no"
    ],
  },
  mounted() {
    this.populateForm();
  },
  watch: {
    pilotData() {
      this.populateForm();
    },
    selectedZone() {
      this.filterOperatorsTable();
    },
    selectedOperator() {
      const result = this.newFilteredOperatorList.find((a) => {
        return a.operator_id === this.selectedOperator;
      });
      this.operator_contact_no = result.user.phone_no;
    },
    operatorsTable() {
      if (this.updateMode) {
        this.filterOperatorsTable();
        this.selectedOperator = this.pilotData.operator.operator_id;
      }
    },
  },
  methods: {
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },

    filterOperatorsTable() {
      const newOperatorsTable = this.operatorsTable.filter((a) => {
        return a.zone.id === this.selectedZone;
      });
      this.newFilteredOperatorList = newOperatorsTable;
    },

    populateForm() {
      if (Object.keys(this.pilotData).length) {
        const fullName = this.pilotData.user.name.split(" ");
        this.first_name = fullName[0];
        this.last_name = fullName[fullName.length - 1];
        this.email = this.pilotData.user.email;
        this.confirmEmail = this.pilotData.user.email;
        this.mobile_no = this.pilotData.user.phone_no;
        this.alternate_contact_no = this.pilotData.user.alternate_phone_no;
        this.address = this.pilotData.address;
        this.weight = this.pilotData.weight;
        this.idProofType = this.pilotData.proof_of_identity_type;
        this.idProofNumber = this.pilotData.proof_of_identity_no;
        this.selectedZone = this.pilotData.operator.zone.id;
      }
    },
  },
  apollo: {
    operatorsTable: {
      // graphql query
      query: GET_OPERATORS_ADD_PILOT,
      update(data) {
        this.dataReady = true;
        return data[OPERATORS_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        this.dataReady = true;
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>
