<template>
  <div class="template-pilot-onboarding">
    <div class="row">
      <div class="col-xl-3 left-card">
        <div class="card">
          <div class="card-body">
            <b-avatar size="6rem" class="mb-3" src=""></b-avatar>
            <label class="col-form-label" for="selectPilotType"
              ><strong>Select Pilot Type</strong></label
            >
            <b-form-radio-group
              id="selectPilotType"
              v-model="selectedPilotType"
              name="radio-inline"
              required
            >
              <template v-for="item in pilotTypeTable">
                <b-form-radio :key="item.value" :value="item.value">
                  {{ item.value }}
                </b-form-radio>
              </template>
            </b-form-radio-group>
          </div>
        </div>
      </div>
      <!-- left card -->

      <div class="col-xl-9 right-card">
        <div class="card">
          <div class="card-body px-0">
            <form-wizard shape="tab" color="#727cf5" error-color="#ff5c75">
              <tab-content
                icon="mdi mdi-account-circle"
                title="Personal Information"
                :before-change="() => validateStep('personalInformation')"
              >
                <personalInformation
                  v-if="loadFormComponents"
                  ref="personalInformation"
                  :updateMode="pilotId"
                  :pilotData="pilotData"
                  @on-validate="mergePartialModels"
                />
              </tab-content>
              <tab-content
                icon="mdi mdi-file-account"
                title="License Information"
                :before-change="() => validateStep('licenseInformation')"
              >
                <licenseInformation
                  v-if="loadFormComponents"
                  ref="licenseInformation"
                  :updateMode="pilotId"
                  :pilotData="pilotData"
                  :clearFormData="showSuccess"
                  @on-validate="mergePartialModels"
                />
              </tab-content>
              <tab-content
                icon="mdi mdi-bank"
                title="Banking Information"
                :before-change="() => validateStep('bankingInformation')"
              >
                <bankingInformation
                  v-if="loadFormComponents"
                  ref="bankingInformation"
                  :updateMode="pilotId"
                  :pilotData="pilotData"
                  :clearFormData="showSuccess"
                  @on-validate="submitFinalForm"
                />
                <b-alert v-if="showSuccess" variant="success" show
                  >Form Submitted Successfully!</b-alert
                >
                <b-alert v-if="errorMessage" show variant="danger">
                  Error: Could not submit the form
                </b-alert>
              </tab-content>
            </form-wizard>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- right card -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  GET_PILOT_TYPE,
  GET_PILOT_DATA_UPDATE,
  GET_USER_BY_EMAIL_ROLE,
} from "@/graphql/queries/queries";
import {
  ADD_USER,
  ADD_USER_ROLE,
  ADD_PILOTS,
  UPDATE_PILOT_ONE,
  UPSERT_PILOT_INSURANCE,
} from "@/graphql/mutations/mutations";
import personalInformation from "@/components/subcomponents/pilot/forms/personalInformation.vue";
import licenseInformation from "@/components/subcomponents/pilot/forms/licenseInformation.vue";
import bankingInformation from "@/components/subcomponents/pilot/forms/bankingInformation.vue";

const PILOT_TYPE_ATTRIBUTE = "pilot_type";
const PILOT_ATTRIBUTE = "pilots_by_pk";

export default {
  components: {
    FormWizard,
    TabContent,
    personalInformation,
    licenseInformation,
    bankingInformation,
  },
  data() {
    return {
      selectedPilotType: "Classic",
      pilotTypeTable: [],
      pilotData: {},
      finalModel: {},
      pilotId: this.$route.params.pilotID,
      pilotDataLoaded: false,
      showSuccess: false,
      errorMessage: false,
    };
  },
  computed: {
    loadFormComponents() {
      if (this.pilotId) {
        return this.pilotDataLoaded;
      }
      return true;
    },
  },

  mounted() {
    this.errorMessage = false;
    this.$apollo.queries.pilotData.skip = true;
    if (this.pilotId) {
      this.$apollo.queries.pilotData.skip = false;
      this.$apollo.queries.pilotData.refetch();
    }
  },

  watch: {
    pilotData() {
      this.selectedPilotType = this.pilotData.pilot_type;
    },
  },
  methods: {
    validateStep(name) {
      return this.$refs[name].validate();
    },
    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model);
        // console.log("last-modal", this.finalModel);
      }
    },
    submitFinalForm(model, isValid) {
      if (isValid) {
        // submitting final model
        this.finalModel = Object.assign({}, this.finalModel, model);
        console.log("model, finalmodel", model, this.finalModel);
        if (this.pilotId) {
          console.log("This is update function");
          if (this.finalModel.insuranceAvailable) {
            this.upsert_pilot_insurance();
          } else {
            const insuranceID = null;
            this.update_pilot(insuranceID);
          }
        } else {
          console.log("this is insert function");
          this.insert_pilot();
        }
        this.$nextTick(() => {
          this.$refs.bankingInformation.$v.$reset();
          this.$refs.licenseInformation.$v.$reset();
          this.$refs.personalInformation.$v.$reset();
        });
      }
    },
    async insert_pilot() {
      const user = this.userPayload();
      // check if pilot exists with same email
      try {
        const getUserByEmailQuery = await this.$apollo.query({
          query: GET_USER_BY_EMAIL_ROLE,
          variables: {
            email: user.email,
            role: "pilot",
          },
        });
        if (getUserByEmailQuery) {
          if (getUserByEmailQuery.data.users.length > 0) {
            this.errorMessage = true;
            console.error("pilot with email already exists");
            return;
          }
          // first insert user
          const addUserMutation = await this.$apollo.mutate({
            mutation: ADD_USER,
            variables: {
              input: user,
            },
          });
          if (addUserMutation) {
            const res = addUserMutation.data.insert_users_one;
            const userID = res.id;

            // now insert pilot by this userID
            const input = this.addPilotPayload();
            input.pilot_id = userID;
            const addPilotMutation = await this.$apollo.mutate({
              mutation: ADD_PILOTS,
              variables: {
                input: input,
              },
            });
            if (addPilotMutation) {
              // add pilot role
              const userRole = {
                user_id: userID,
                role: "pilot",
              };
              const addUserRoleMutation = await this.$apollo.mutate({
                mutation: ADD_USER_ROLE,
                variables: {
                  input: userRole,
                },
              });
              if (addUserRoleMutation) {
                this.showSuccess = true;
                setTimeout(() => {
                  this.$router.push({ name: "pilot" });
                }, 3000);
              }
            }
          }
        }
      } catch (error) {
        this.errorMessage = true;
        console.error(error);
      }
    },
    async upsert_pilot_insurance() {
      const insurance_data = {
        insurance_provider: this.finalModel.insurance_provider,
        issue_date: this.finalModel.policy_start_date,
        expiry_date: this.finalModel.policy_end_date,
        policy_no: this.finalModel.insurance_policy_no,
      };

      try {
        const upsertPilotInsuranceMutation = await this.$apollo.mutate({
          mutation: UPSERT_PILOT_INSURANCE,
          variables: {
            data: insurance_data,
          },
        });
        if (upsertPilotInsuranceMutation) {
          // extract insurance id
          const insuranceID =
            upsertPilotInsuranceMutation.data.insert_pilot_insurance_one.id;
          // Now run the pilot update query
          this.update_pilot(insuranceID);
        }
      } catch (error) {
        this.errorMessage = true;
        console.error(error);
      }
    },
    async update_pilot(insuranceID) {
      const pilotId = this.pilotId;
      const license_id = this.pilotData.license_id;
      const bank_details_id = this.pilotData.bank_details_id;
      const pilotData = {
        insurance_id: insuranceID,
        pilot_type: this.selectedPilotType,
        operator_id: this.finalModel.selectedOperator,
        proof_of_identity_no: this.finalModel.idProofNumber,
        proof_of_identity_type: this.finalModel.idProofType,
        tandem_flying_hours: this.finalModel.tandemHours,
        address: this.finalModel.address,
        weight: this.finalModel.weight,
      };
      const user = {
        name: `${this.finalModel.first_name} ${this.finalModel.last_name}`,
        email: this.finalModel.email,
        phone_no: this.finalModel.mobile_no,
        alternate_phone_no: this.finalModel.alternate_contact_no,
      };
      const pilot_license = {
        expiry_date: this.finalModel.license_expiry_date,
        first_issue_date: this.finalModel.license_first_issued_date,
        issue_date: this.finalModel.license_issued_date,
        issuing_authority: this.finalModel.licenseAuthority,
        license_no: this.finalModel.license_no,
      };
      const bank_account = {
        account_name: this.finalModel.account_name,
        account_no: this.finalModel.accountNumber,
        account_type: this.finalModel.accountType,
        bank_name: this.finalModel.bankAndBranch,
        ifsc: this.finalModel.ifsCode,
        pan: this.finalModel.pancardNo,
      };

      try {
        const updatePilotOneMutation = await this.$apollo.mutate({
          mutation: UPDATE_PILOT_ONE,
          variables: {
            pilotId,
            license_id,
            bank_details_id,
            pilotData,
            user,
            pilot_license,
            bank_account,
          },
        });
        if (updatePilotOneMutation) {
          this.showSuccess = true;
          setTimeout(() => {
            this.$router.push({ name: "pilot" });
          }, 3000);
        }
      } catch (error) {
        this.errorMessage = true;
        console.error(error);
      }
    },
    userPayload() {
      return {
        name: `${this.finalModel.first_name} ${this.finalModel.last_name}`,
        email: this.finalModel.email,
        phone_no: this.finalModel.mobile_no,
        alternate_phone_no: this.finalModel.alternate_contact_no,
      };
    },

    addPilotPayload() {
      let insertPilotInput = {
        pilot_type: this.selectedPilotType,
        address: this.finalModel.address,
        weight: this.finalModel.weight,
        operator_id: this.finalModel.selectedOperator,
        pilot_license: {
          data: {
            license_no: this.finalModel.license_no,
            issuing_authority: this.finalModel.licenseAuthority,
            issue_date: this.finalModel.license_issued_date,
            expiry_date: this.finalModel.license_expiry_date,
            first_issue_date: this.finalModel.license_first_issued_date,
          },
        },
        tandem_flying_hours: this.finalModel.tandemHours,
        proof_of_identity_type: this.finalModel.idProofType,
        proof_of_identity_no: this.finalModel.idProofNumber,
        bank_account: {
          data: {
            account_name: this.finalModel.account_name,
            account_type: this.finalModel.accountType,
            account_no: this.finalModel.accountNumber,
            ifsc: this.finalModel.ifsCode,
            bank_name: this.finalModel.bankAndBranch,
            pan: this.finalModel.pancardNo,
          },
        },
      };

      if (this.finalModel.insuranceAvailable) {
        // check if insurance data is provided
        insertPilotInput.pilot_insurance = {
          data: {
            insurance_provider: this.finalModel.insurance_provider,
            policy_no: this.finalModel.insurance_policy_no,
            issue_date: this.finalModel.policy_start_date,
            expiry_date: this.finalModel.policy_end_date,
          },
        };
      }

      return insertPilotInput;
    },
  },
  apollo: {
    pilotTypeTable: {
      // graphql query
      query: GET_PILOT_TYPE,
      update(data) {
        return data[PILOT_TYPE_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
    pilotData: {
      // graphql query
      query: GET_PILOT_DATA_UPDATE,
      variables() {
        // Use vue reactive properties here
        return {
          pilot_id: this.pilotId,
        };
      },
      skip: true,
      update(data) {
        this.pilotDataLoaded = true;
        return data[PILOT_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss">
.template-pilot-onboarding {
  padding-top: 24px;

  .right-card {
    fieldset {
      background: #efefef3b;
      padding: 10px 30px;
      display: block;
      box-shadow: #01010133 1px 1px 4px;
    }

    fieldset.insurance-radio {
      background: none;
      padding: 0;
      display: block;
      box-shadow: none;

      .custom-control.custom-radio {
        display: inline-block;
        margin: 20px 20px 0 20px;
      }
    }

    .vue-form-wizard {
      .wizard-tab-content {
        padding: 20px 10px 10px;
      }

      .wizard-icon-circle.tab_shape {
        height: 30px !important;
      }

      span.stepTitle {
        background: #f1f3fa;
        display: block;
        width: 100%;
      }

      span.stepTitle.active {
        background: rgb(114, 124, 245);
        color: #fff !important;
      }

      fieldset.form-group h5 {
        background: #efefef;
        margin: -10px -30px 20px;
        padding: 10px 20px;
      }
    }
  }

  .left-card {
    text-align: center;

    label[for="selectPilotType"] {
      display: block;
    }

    .b-avatar {
      margin: auto;
    }
  }
}
</style>
