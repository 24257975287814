<template>
  <div>
    <b-form-group>
      <h5>Banking Details</h5>
      <div class="form-group row">
        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.account_name.$error }"
          >
            <label class="col-form-label" for="accountName">Account Name</label>
            <input
              id="accountName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.account_name.$error }"
              placeholder="Account Name"
              v-model="account_name"
            />
            <span
              v-if="$v.account_name.$error && !$v.account_name.required"
              class="help-block invalid-feedback"
              >Account name is required</span
            >
          </div>
        </div>
        <!-- Account Name -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.accountType.$error }"
          >
            <label class="col-form-label" for="accountType">Account Type</label>
            <b-form-select
              id="accountType"
              v-model="accountType"
              class="form-control"
              :class="{ 'is-invalid': $v.accountType.$error }"
            >
              <b-form-select-option :value="null"
                >Select account type</b-form-select-option
              >
              <b-form-select-option value="current"
                >Current Account</b-form-select-option
              >
              <b-form-select-option value="savings"
                >Savings Account</b-form-select-option
              >
            </b-form-select>
            <span
              v-if="$v.accountType.$error && !$v.accountType.required"
              class="help-block invalid-feedback"
              >Account type is required</span
            >
          </div>
        </div>
        <!-- Account Type -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.accountNumber.$error }"
          >
            <label class="col-form-label" for="accountNumber"
              >Account Number</label
            >
            <input
              id="accountNumber"
              type="text"
              inputmode="numeric"
              class="form-control"
              :class="{ 'is-invalid': $v.accountNumber.$error }"
              placeholder="Enter account number"
              v-model.trim="$v.accountNumber.$model"
              @paste.prevent
            />
            <span
              v-if="$v.accountNumber.$error && !$v.accountNumber.required"
              class="help-block invalid-feedback"
              >Account number is required</span
            >
            <span
              v-else-if="!$v.accountNumber.numeric"
              class="help-block invalid-feedback"
              >Account number must be positive numeric</span
            >
          </div>
        </div>
        <!-- Account Number -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.accountNumber_confirmation.$error }"
          >
            <label class="col-form-label" for="accountNumberRepeat"
              >Repeat Account Number</label
            >
            <input
              id="accountNumberRepeat"
              type="text"
              inputmode="numeric"
              class="form-control"
              :class="{ 'is-invalid': $v.accountNumber_confirmation.$error }"
              placeholder="Re-enter account number"
              v-model.trim="$v.accountNumber_confirmation.$model"
              @paste.prevent
            />
            <span
              v-if="
                $v.accountNumber_confirmation.$error &&
                !$v.accountNumber_confirmation.sameAsaccountNo
              "
              class="help-block invalid-feedback"
            >
              Account number doesnot match
            </span>
          </div>
        </div>
        <!-- Re-enter Account Number -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.ifsCode.$error }"
          >
            <label class="col-form-label" for="ifsCode">IFS Code</label>
            <input
              v-model="$v.ifsCode.$model"
              v-on:blur="ifscChanged"
              id="ifsCode"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.ifsCode.$error }"
              placeholder="Enter IFS code"
            />
            <span
              v-if="$v.ifsCode.$error && !$v.ifsCode.required"
              class="help-block invalid-feedback"
              >ISFC Code is required</span
            >
            <span
              v-if="$v.ifsCode.$error && !$v.ifsCode.valid"
              class="help-block invalid-feedback"
              >Invalid IFSC Code</span
            >
          </div>
        </div>
        <!-- IFS Code -->

        <div class="col-sm-6">
          <div
            class="form-group row mb-1 px-1"
            :class="{ 'has-error': $v.bankAndBranch.$error }"
          >
            <label class="col-form-label" for="bankName">Bank</label>
            <input
              v-model="bankAndBranch"
              id="bankName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.bankAndBranch.$error }"
              disabled="true"
            />
            <span
              v-if="$v.bankAndBranch.$error && !$v.bankAndBranch.required"
              class="help-block invalid-feedback"
              >Bank and branch is required</span
            >
          </div>
        </div>
        <!-- Bank Name -->
        <!-- Note: Get bank and branch details using IFS Code via razorPay API: https://ifsc.razorpay.com/ -->

        <div class="col-sm-6">
          <div class="form-group row mb-1 px-1">
            <label class="col-form-label" for="pilotPAN">PAN number</label>
            <input
              id="pilotPAN"
              type="text"
              class="form-control"
              placeholder="Enter PAN number"
              v-model="pancardNo"
              :class="{ 'is-invalid': $v.pancardNo.$error }"
            />
            <span
              v-if="$v.pancardNo.$error && !$v.pancardNo.required"
              class="help-block invalid-feedback"
              >Pancard no is required</span
            >
          </div>
        </div>
        <!-- Pilot PAN -->
      </div>
    </b-form-group>
    <!-- end row -->
  </div>
</template>

<script>
import { required, sameAs, numeric } from "vuelidate/lib/validators";

export default {
  name: "bankingInformation",
  props: {
    pilotData: {
      type: Object,
    },
    clearFormData: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      account_name: "",
      accountNumber: null,
      accountNumber_confirmation: null,
      accountType: null,
      ifsCode: null,
      bankAndBranch: "",
      pancardNo: null,
      invalidIfsc: false,
    };
  },
  validations: {
    account_name: {
      required,
    },
    accountNumber: {
      required,
      numeric,
    },
    accountNumber_confirmation: {
      required,
      sameAsaccountNo: sameAs("accountNumber"),
    },
    accountType: {
      required,
    },
    ifsCode: {
      required,
      valid: (_, vm) => !vm.invalidIfsc,
    },
    bankAndBranch: {
      required,
      valid: (_, vm) => !vm.invalidIfsc,
    },
    pancardNo: {
      required,
    },
    form: [
      "account_name",
      "accountNumber",
      "accountNumber_confirmation",
      "accountType",
      "ifsCode",
      "bankAndBranch",
      "pancardNo",
    ],
  },
  watch: {
    pilotData() {
      this.populateForm();
    },
    clearFormData() {
      this.account_name = "";
      this.accountNumber = null;
      this.accountNumber_confirmation = null;
      this.accountType = null;
      this.ifsCode = null;
      this.bankAndBranch = "";
      this.pancardNo = null;
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    async ifscChanged() {
      const url = "https://ifsc.razorpay.com/" + this.ifsCode;
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await fetch(url);
        if (response.status === 200) {
          const bankData = await response.json();
          this.bankName = bankData["BANK"];
          this.bankBranch = bankData["BRANCH"];
          this.bankAndBranch = this.bankName + " " + this.bankBranch;
          this.invalidIfsc = false;
        } else {
          // error
          this.invalidIfsc = true;
          this.bankAndBranch = "";
        }
      } catch (error) {
        throw error;
      }
    },
    populateForm() {
      if (Object.keys(this.pilotData).length) {
        this.account_name = this.pilotData.bank_account.account_name;
        this.accountNumber = this.pilotData.bank_account.account_no;
        this.accountNumber_confirmation = this.pilotData.bank_account.account_no;
        this.accountType = this.pilotData.bank_account.account_type;
        this.ifsCode = this.pilotData.bank_account.ifsc;
        this.bankAndBranch = this.pilotData.bank_account.bank_name;
        this.pancardNo = this.pilotData.bank_account.pan;
      }
    },
    validate() {
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },
  },
};
</script>
